/* Navbar.css */
.navbar {
    background-color: #B4C8CD;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    color: rgb(0, 0, 0);
    overflow: hidden; /* Asegura que el pseudo-elemento no se salga de los bordes */
    .logo{
        width: 50px;
        .logo-img{
            width: 100%;
        }
    }
}

.navbar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff34;
    filter: blur(2.5px);
    z-index: -1; /* Coloca el fondo detrás del contenido */
}

.navbar .logo {
    padding: 15px 20px;
    z-index: 2; /* Asegura que el logo esté sobre el fondo borroso */
}

.nav-links {
    list-style: none;
    display: flex;
    z-index: 2; /* Asegura que los enlaces estén sobre el fondo borroso */
}

.nav-links li {
    padding: 15px 20px;
}

.nav-links a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.burger {
    display: none;
    cursor: pointer;
    z-index: 2; /* Asegura que el burger esté sobre el fondo borroso */
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 5px;
    transition: all 0.3s ease;
}

/* Estilo responsive */
@media screen and (max-width: 768px) {
    .nav-links {
        position: absolute;
        right: 0px;
        height: auto;
        top: 4vh;
        background-color: #B4C8CD;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: translateY(-150%);
        border-radius: 0px 0px 0px 20px;
        transition: transform 0.5s ease-in;
        z-index: 2; /* Asegura que el menú responsive esté sobre el fondo borroso */
    }

    .nav-links.active {
        transform: translateX(0%);
    }

    .burger {
        display: block;
        margin-right: 20px;
    }
}
