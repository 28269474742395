.home-container{
    background-image: url(../../assets/hero.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
   
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /*** ESTILOS BOTÓN SLIDE RIGHT ***/
.ov-btn-slide-right {
    background: #fff; /* color de fondo */
    color: #4741d7; /* color de fuente */
    border: 2px solid #4741d7; /* tamaño y color de borde */
    padding: 10px 40px;
    border-radius: 3px; /* redondear bordes */
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: inline-block;
  }
  .ov-btn-slide-right:hover {
    color: #fff; /* color de fuente hover */
  }
  .ov-btn-slide-right::after {
    content: "";
    background: #4741d7; /* color de fondo hover */
    position: absolute;
    z-index: -1;
    padding: 16px 20px;
    display: block;
    top: 0;
    bottom: 0;
    left: 100%;
    right: -100%;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }
  .ov-btn-slide-right:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;
  }
}
/* Media queries para tabletas */
@media (max-width: 485px) {
  .home-container{
      height: 100vh;
      background-size: 100%;
      background-attachment: fixed;
  }
}
/* Media queries para tabletas */
@media (max-width: 485px) {
    .home-container{
        height: 100vh;
        background-size: 400%;
        background-attachment: fixed;
    }
  }
  /* Media queries para tabletas */
@media (max-width: 425px) {
  .home-container{
      height: 100vh;
      background-size: 400%;
      background-attachment: fixed;
  }
}
/* Media queries para tabletas */
@media (max-width: 325px) {
  .home-container{
      height: 100vh;
      background-size: 400%;
      background-attachment: fixed;
  }
}